import * as Yup from "yup";

import { cpfValidator } from "./cpf.validator";
import { passwordValidator } from "./password.validator";

Yup.addMethod(Yup.string, "cpf", function (message) {
  const errorMessage = message || "cpf inválido";
  return this.test({
    name: "name",
    message: errorMessage,
    test: (cpf = "") => cpfValidator(cpf),
  });
});

export const firstName = Yup.string()
  .trim()
  .required("Campo obrigatório")
  .min(3, "Mínimo 3 caracteres")
  .max(32, "Máximo 32 caracteres")
  .matches(/^([a-zA-ZÀ-ú\s]+)$/gi, "Digite um nome válido");

export const lastName = Yup.string()
  .trim()
  .required("Campo obrigatório")
  .min(3, "Mínimo 3 caracteres")
  .max(50, "Máximo 50 caracteres")
  .matches(/^[a-zA-ZÀ-ú\s]+$/, "Digite um sobrenome válido");

export const socialName = Yup.string()
  .trim()
  .optional()
  .max(32, "Máximo 32 caracteres")
  .matches(/^([a-zA-ZÀ-ú\s]+)$/gi, "Digite um nome social válido");

export const phone = Yup.string()
  .required("Campo obrigatório")
  .matches(
    /^([(]?[0-9]{2}[)]?)\s?[0-9]{4,5}\-?[0-9]{4}$/gi,
    "Digite um telefone válido"
  );

export const foreignPhone = Yup.string()
  .required("Campo obrigatório")
  .test("Digite um telefone válido", "Digite um telefone válido", (value) => {
    const response = value?.replace(/\D/g, "");
    const phoneRegex = /^\+([0-9]{1,3})\s?([0-9]{9,15})$/;
    return phoneRegex.test(`+${response}`);
  });

export const documentType = Yup.string().notRequired();

export const passwordCurrent = Yup.string().required("Campo obrigatório");

export const password = Yup.string()
  .required("Campo obrigatório")
  .max(32)
  .test("Senha inválida", "Digite uma senha válida", (value: string) => {
    return passwordValidator
      .map((elem) => elem.test(value))
      .every((value) => value);
  })
  .max(32, "A senha deve ter no máximo 32 caracteres")
  .min(8, "A senha deve ter no mínimo 8 caracteres");

export const confirmField = (field: any, message: string) =>
  Yup.string()
    .trim("")
    .strict(true)
    .required(`Campo confirmação obrigatório`)
    .oneOf([Yup.ref(field), null], message);
