import { FormikErrors } from "formik/dist/types";
import React from "react";

import { InputCustom, SelectCustom } from "../../../../components";
import {
  SideFormAccountContainer,
  SideFormAccountInputContent,
  SideFormAccountWrapper,
} from "./style";

export interface ISideFormAccount {
  errors: FormikErrors<{
    firstName: string;
    lastName: string;
    socialName: string;
    phone: string;
    gender: string;
  }>;
  values: FormikErrors<{
    documentType: string;
    firstName: string;
    lastName: string;
    socialName: string;
    phone: string;
    gender: string;
  }>;
  userData: {
    email: string;
    documentType: string;
    birthDate: string;
    document: string;
  };
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

export const SideFormAccount: React.FC<ISideFormAccount> = ({
  errors,
  handleBlur,
  handleChange,
  userData,
  values,
}) => {
  const validateMaskPhone = React.useCallback(() => {
    const response = values?.phone?.replace(/\D/g, "");
    if (response?.length === 8) {
      return "9999-9999";
    }
    if (response?.length === 9) {
      return "99999-9999";
    }
    if (String(response?.charAt(2)) !== String(9)) {
      return "(99) 9999-9999";
    } else {
      return "(99) 99999-9999";
    }
  }, [values.phone]);

  const genderLabel: Record<string, string> = {
    ["M"]: "Masculino",
    ["F"]: "Feminino",
  };

  return (
    <SideFormAccountContainer>
      <SideFormAccountWrapper>
        <SideFormAccountInputContent>
          <InputCustom
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="none"
            bottom="20px"
            className="socialName"
            error={errors.socialName}
            id={"home-form-account-input-socialName"}
            mask=""
            maskChar="_"
            onBlur={handleBlur("socialName")}
            onChange={handleChange("socialName")}
            placeholder={"Nome Social"}
            value={values.socialName}
          />
        </SideFormAccountInputContent>
        <SideFormAccountInputContent>
          <InputCustom
            disabled
            information="Esse campo não pode ser editado"
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="none"
            bottom="20px"
            className="firstName"
            error={errors.firstName}
            id={"home-form-account-input-firstName"}
            mask=""
            maskChar="_"
            placeholder={"Nome"}
            value={`${values.firstName} ${values.lastName}`}
          />
        </SideFormAccountInputContent>
        <SideFormAccountInputContent
          width={userData?.documentType?.toLowerCase() !== "cpf" ? "100%" : ""}
        >
          <InputCustom
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="none"
            bottom="20px"
            className={"document"}
            disabled
            information="Esse campo não pode ser editado"
            id={"home-form-account-input-document"}
            mask={
              userData?.documentType?.toLowerCase() === "cpf"
                ? "999.999.999-99"
                : ""
            }
            maskChar="_"
            placeholder={
              userData?.documentType?.toLowerCase() === "cpf"
                ? "CPF"
                : "PASSAPORTE"
            }
            value={userData?.document?.length > 0 ? userData?.document : ""}
          />
        </SideFormAccountInputContent>
        <SideFormAccountInputContent>
          <InputCustom
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="none"
            bottom="20px"
            className={"birthDate"}
            disabled
            id={"home-form-account-input-birthDate"}
            information="Esse campo não pode ser editado"
            mask="99/99/9999"
            maskChar="_"
            placeholder="Data de Nascimento"
            value={userData?.birthDate?.length > 0 ? userData?.birthDate : ""}
          />
        </SideFormAccountInputContent>
        <SideFormAccountInputContent>
          <InputCustom
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="none"
            bottom="20px"
            className={"gender"}
            disabled
            id={"home-form-account-input-gender"}
            information="Esse campo não pode ser editado"
            mask=""
            maskChar="_"
            placeholder="Sexo"
            value={genderLabel[values.gender]}
          />
        </SideFormAccountInputContent>
        <SideFormAccountInputContent>
          <InputCustom
            disabled
            information="Esse campo não pode ser editado"
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="none"
            bottom="20px"
            className={"phone"}
            error={errors.phone}
            id={"home-form-account-input-phone"}
            mask={
              userData?.documentType?.toLowerCase() === "cpf"
                ? validateMaskPhone()
                : "+999999999999999"
            }
            maskChar="_"
            placeholder={
              userData?.documentType?.toLowerCase() === "cpf"
                ? "DDD + Telefone"
                : "Código do País + DDD + Telefone"
            }
            value={values.phone}
          />
        </SideFormAccountInputContent>
        <SideFormAccountInputContent>
          <InputCustom
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="none"
            bottom="20px"
            className={"email"}
            disabled
            id={"home-form-account-input-email"}
            information="Esse campo não pode ser editado"
            mask=""
            maskChar="_"
            placeholder={"E-mail"}
            value={userData.email}
          />
        </SideFormAccountInputContent>
      </SideFormAccountWrapper>
    </SideFormAccountContainer>
  );
};
