import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiMinhaConta } from "../../service/apiMinhaConta";

import { apiPatient } from "../../service/apiPatient";
import { DependentsParser } from "../../utils/DependentsParser";
import { IDependent, IResponseDependents } from "./types/dependents.interface";

const prefix = "conecta:home-microfrontend/dependents";

export const setDependents = createAction<IDependent[]>(
  `${prefix}/setDependents`
);

export const setDependentsRequest = createAsyncThunk(
  `${prefix}/setDependentsRequest`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await apiMinhaConta.get('/patient-portal/v1/dependent')

      return {
        dependents: data.map(DependentsParser.parser)
      } as IResponseDependents;
    } catch (err) {
      const { message, error } = err?.response?.data || {};
      return rejectWithValue(
        message || error || "Erro inesperado. Tente novamente mais tarde"
      );
    }
  }
);
