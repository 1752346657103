export class NameValidator {
  private readonly name: string;
  private readonly isSocialName: boolean;

  constructor(name: string, isSocialName = true) {
    this.name = name;
    this.isSocialName = isSocialName;
  }

  // private whiteList = ['de', 'da', 'das', 'do', 'dos'];

  private tableErrors = [
    {
      callback: () => {
        if (this.name.length > 0) {
          return false;
        }

        return true;
      },
      message: () =>
        this.isSocialName
          ? 'O nome social deve conter pelo menos um nome e um sobrenome'
          : 'O preenchimento é obrigatório',
    },
    {
      callback: () => {
        if (/[0-9]/.test(this.name)) {
          return true;
        }

        return false;
      },
      message: () => 'Nome inválido',
    },
    {
      callback: () => {
        if (this.name.length < 5) {
          return true;
        }

        return false;
      },
      message: () => 'Nome muito curto',
    },
    {
      callback: () => {
        const [firstName] = this.name?.split(' ');
        if (firstName?.length === 1) {
          return true;
        }

        return false;
      },
      message: () => 'Primeiro nome muito curto',
    },
    {
      callback: () => {
        if (this.name.length > 32) {
          return true;
        }

        return false;
      },
      message: () => 'Nome muito longo',
    },
    {
      callback: () => {
        const fullName = String(this.name).trim().split(' ');

        if (fullName.length === 1) {
          return true;
        }

        return false;
      },
      message: () =>
        this.isSocialName
          ? 'O nome social deve conter pelo menos um nome e um sobrenome'
          : 'Preencha o campo com nome e sobrenome',
    },
    {
      callback: () => {
        const [_, ...undernames] = String(this.name).trim().split(' ');

        const allValid = undernames.every((undername) => {
          if (undername.length >= 2) {
            return true;
          }
          return false;
        });

        return !allValid;
      },
      message: () => 'Sobrenomes não devem ser abreviados',
    },
    {
      callback: () => {
        if (!this.isSocialName) {
          return false;
        }
        const fullName = String(this.name).trim().split(' ');

        if (fullName.length > 2) {
          return true;
        }

        return false;
      },
      message: () => 'Nome social deve ter apenas um nome e um sobrenome',
    },
    {
      callback: () => {
        return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.name);
      },
      message: () => 'Não deve ter caracteres especiais',
    },
    // {
    //   callback: () => {
    //     const names = this.name.split(' ');
    //     const allValidate = names.every((name) => {
    //       if (this.whiteList.includes(name)) {
    //         return true;
    //       }
    //       const firstLetter = name[0].toUpperCase() === name[0];
    //       const others = name.slice(1);
    //       const othersLetter = others.toLowerCase() === others;
    //       return firstLetter && othersLetter;
    //     });
    //     return !allValidate;
    //   },
    //   message: () => 'Nome deve começar com letra maiuscula',
    // },
  ];

  private errorCheck() {
    const error = this.tableErrors.find((e) => e.callback());

    return error;
  }

  valid() {
    const error = this.errorCheck();
    if (error) {
      return false;
    }
    return true;
  }

  errorMessage(): string {
    return this.errorCheck()?.message() || '';
  }

  toString() {
    return this.name;
  }
}
