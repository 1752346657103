import React from "react";
import { FiUserPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../@types/action";
import { BackgroundOverlay, Circle, GoBack } from "../../../../../components";
import { ModalSimple } from "../../../../../components/Modal";
import { getListProfilesSelector } from "../../../../../core/Dependents/selectors";
import { IDependent } from "../../../../../core/Dependents/types/dependents.interface";
import { setVisibleModal } from "../../../../../core/Modal/actions";
import { getVisibleModalSelector } from "../../../../../core/Modal/selectors";
import { setRouteName } from "../../../../../core/Navigation/actions";
import { setSelectedPatient } from "../../../../../core/Patient/actions";
import { getLoadingPatientReducerSelector } from "../../../../../core/Patient/selectors";
import theme from "../../../../../global/theme";
import {
  ModalSelectPatientAvatarImage,
  ModalSelectPatientButton,
  ModalSelectPatientListGroup,
  ModalSelectPatientListItem,
  ModalSelectPatientListItemContent,
  ModalSelectPatientLoading,
  ModalSelectPatientName,
  ModalSelectPatientTitle,
} from "./style";

export interface IModalSelectPatientProps {
  id: string;
}

export const ModalSelectPatient: React.FC<IModalSelectPatientProps> = ({
  id,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const dependents = useSelector(getListProfilesSelector);
  const selectedLoading = useSelector(getLoadingPatientReducerSelector);
  const loadingPatientDocument = selectedLoading("loadingGetPatientDocuments");
  const selectedModal = useSelector(getVisibleModalSelector);
  const { visibleModal, typeModal } = selectedModal("modalSelectedPatient");

  const [indexPatient, setIndexPatient] = React.useState(null);

  const handleCloseModal = React.useCallback(() => {
    dispatch(
      setVisibleModal({
        modalSelectedPatient: false,
        nextSchedule: false,
        typeModal: "",
      })
    );
  }, [dispatch]);

  const handleSelectedProfile = React.useCallback(
    async (dependent: IDependent, index: number) => {
      setIndexPatient(index);
      dispatch(setSelectedPatient(dependent));
    },
    [dispatch]
  );

  const handleAddDependent = React.useCallback(() => {
    dispatch(
      setVisibleModal({
        modalSelectedPatient: false,
        nextSchedule: false,
        typeModal: "modal_attendance_immediate",
      })
    );
    dispatch(setRouteName("/dependentes"));
  }, [dispatch]);

  React.useEffect(() => {
    if (loadingPatientDocument) {
    }
  }, [loadingPatientDocument]);

  return (
    <React.Fragment>
      <BackgroundOverlay
        id={"home-background-overlay"}
        visible={visibleModal}
      />
      <ModalSimple id={id} visible={visibleModal}>
        <GoBack
          label="Voltar"
          id={"home-btn-close-modal-select-patient"}
          onClick={handleCloseModal}
        />
        <ModalSelectPatientTitle>
          Selecione para quem é o atendimento
        </ModalSelectPatientTitle>
        <div style={{}}>
          <ModalSelectPatientListItem>
            {dependents.map((dependent, index) => (
              <ModalSelectPatientButton
                disabled={loadingPatientDocument}
                selectedPatient={indexPatient === index}
                key={index}
                onClick={() => {
                  handleSelectedProfile(dependent, index);
                }}
              >
                <ModalSelectPatientListItemContent>
                  {loadingPatientDocument && indexPatient === index ? (
                    <ModalSelectPatientLoading>
                      <Circle
                        id={`home-modal-select-patient-dependent-progress-icon-${index}`}
                        color={theme.colors.gray_secondary}
                      />
                    </ModalSelectPatientLoading>
                  ) : (
                    <ModalSelectPatientAvatarImage
                      id={`home-modal-select-patient-button-dependent-${index}`}
                      borderRadius="20px"
                      size="40px"
                      src={
                        dependent.photoProfile !== null
                          ? dependent.photoProfile
                          : ""
                      }
                    />
                  )}
                  <ModalSelectPatientName>
                    {dependent.diversity?.socialName ||
                      dependent.name.toLowerCase()}
                  </ModalSelectPatientName>
                </ModalSelectPatientListItemContent>
              </ModalSelectPatientButton>
            ))}
          </ModalSelectPatientListItem>
        </div>
        <ModalSelectPatientListGroup>
          <ModalSelectPatientButton
            disabled={loadingPatientDocument}
            selectedPatient={false}
            onClick={handleAddDependent}
          >
            <ModalSelectPatientListItemContent>
              <ModalSelectPatientAvatarImage
                id={"home-modal-select-patient-button-add-dependent"}
                borderRadius="20px"
                type="icon"
                size="40px"
              >
                <FiUserPlus size={23} color={theme.colors.white_primary} />
              </ModalSelectPatientAvatarImage>
              <ModalSelectPatientName>Adicionar</ModalSelectPatientName>
            </ModalSelectPatientListItemContent>
          </ModalSelectPatientButton>
        </ModalSelectPatientListGroup>
      </ModalSimple>
    </React.Fragment>
  );
};
