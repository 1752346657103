import { IMyAccount, IMyAccountEdit } from "../core/Patient/types/IMyAccount";
import { IPatient, IPutPatient } from "../core/Patient/types/patient.interface";

export class PersonParser {
  parseMyAccountToPatient(myAccount: IMyAccount): IPatient {
    return {
      documentType: myAccount.cpf ? "CPF" : "PASSAPORTE",
      sex: myAccount.biologicalSex === "MALE" ? "M" : "F",
      _id: myAccount._id,
      firstName: myAccount.name.split(" ")[0],
      lastName: myAccount.name.split(" ").splice(1).join(" "),
      diversity: myAccount.diversity,
      email: myAccount.email,
      phone: myAccount.phone?.substring(3),
      document: myAccount.cpf || myAccount.foreignDocument,
      birthDate: myAccount.birthDate,
      rg: "",
      fileDocuments: [],
      photoProfile: myAccount.imageProfileUrl,
    };
  }

  parsePatientToMyAccountEdit(patient: IPutPatient): IMyAccountEdit {
    return {
      value: patient.socialName,
    };
  }
}
