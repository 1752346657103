import { format, formatISO } from "date-fns";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { unwrapResult } from "@reduxjs/toolkit";

import { AppDispatch } from "../../../../@types/action";
import { Circle } from "../../../../components";
import {
  putPatientPasswordRequest,
  putPatientRequest,
} from "../../../../core/Patient/actions";
import {
  getLoadingPatientReducerSelector,
  getPatientSelector,
} from "../../../../core/Patient/selectors";
import theme from "../../../../global/theme";
import { useFieldRefs } from "../../../../utils/functions";
import {
  validationUpdatedPasswordSchema,
  validationUpdatedPatientSchema,
} from "../../../../validators/patient.scheme.validator";
import { FormAccountOptions, TFormAccountOptionsProps } from "../../types";
import { SideFormAccount } from "../SideFormAccount/SideFormAccount";
import { SideFormPassword } from "../SideFormPassword/SideFormPassword";
import {
  SideFormButton,
  SideFormButtonLabel,
  SideFormButtonsWrapper,
} from "./style";

interface ISideForm {
  typeForm: TFormAccountOptionsProps;
}

export const SideForm: React.FC<ISideForm> = ({ typeForm }) => {
  const dispatch: AppDispatch = useDispatch();
  const patient = useSelector(getPatientSelector);
  const selectedLoading = useSelector(getLoadingPatientReducerSelector);
  const loadingPutAccount =
    selectedLoading("loadingPutPatient") ||
    selectedLoading("loadingPutPatientPassword");

  const patientData = {
    name: patient?.firstName,
    lastName: patient?.lastName,
    socialName: patient?.diversity?.socialName,
    phone: patient?.phone,
    email: patient?.email,
    document: patient?.document.replace(/([^0-9a-zA-Z])/gi, ""),
    documentType: patient?.documentType,
    rg: patient?.rg?.length > 0 ? patient.rg : "",
    birthDate: format(
      new Date(
        formatISO(
          new Date(
            patient !== null && patient?.birthDate
              ? patient?.birthDate
              : new Date()
          )
        )
      ),
      "dd/MM/yyyy"
    ),
    sex: patient?.sex,
  };

  const [updatePatient, setPatient] = React.useState({
    documentType: patientData.documentType,
    firstName: patientData.name,
    lastName: patientData.lastName,
    socialName: patientData.socialName,
    phone: patientData.phone,
    gender: patientData.sex,
  });

  const [updatePassword, setPasssword] = React.useState({
    passwordCurrent: "",
    password: "",
    confirmPassword: "",
  });

  const [currentRefsPatient, focusPatient] = useFieldRefs(updatePatient);
  const [currentRefsPassword, focusPassword] = useFieldRefs(updatePatient);

  const formikUpdatePatient = useFormik({
    initialValues: updatePatient,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationUpdatedPatientSchema,
    async onSubmit(values, actions) {
      await dispatch(
        putPatientRequest({
          birthDate: patient.birthDate,
          document: patient.document,
          socialName: values.socialName,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          sex: values.gender,
        })
      )
        //@ts-ignore
        .then(unwrapResult)
        .then(() => {
          actions.setSubmitting(false);
          actions.resetForm({ values });
        });
    },
  });

  const formikUpdatePassword = useFormik({
    initialValues: updatePassword,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationUpdatedPasswordSchema,
    async onSubmit(values, actions) {
      await dispatch(
        putPatientPasswordRequest({
          newPassword: values.password,
          oldPassword: values.passwordCurrent,
        })
      )
        //@ts-ignore
        .then(unwrapResult)
        .then(() => {
          actions.setSubmitting(false);
          actions.resetForm();
        });
    },
  });

  return typeForm === FormAccountOptions.FORM_ACCOUNT ? (
    <React.Fragment>
      <SideFormAccount
        errors={formikUpdatePatient.errors}
        handleBlur={formikUpdatePatient.handleBlur}
        handleChange={formikUpdatePatient.handleChange}
        userData={patientData}
        values={formikUpdatePatient.values}
      />
      <SideFormButtonsWrapper justifyContent="flex-end">
        {patient?.fileDocuments.length === 0 && (
          <SideFormButton
            id={"home-form-account-btn-upload-documents"}
            data-testid={"test-home-form-account-btn-upload-documents"}
            marginRight={12}
            marginBottom={20}
          >
            <SideFormButtonLabel>Upload documentos</SideFormButtonLabel>
          </SideFormButton>
        )}
        <SideFormButton
          disabled={
            !formikUpdatePatient.dirty ||
            !formikUpdatePatient.isValid ||
            loadingPutAccount
          }
          id={"home-form-account-btn-confirm"}
          data-testid={"test-home-form-account-btn-confirm"}
          onClick={() => formikUpdatePatient.handleSubmit()}
          type="button"
        >
          {loadingPutAccount ? (
            <Circle
              id={"home-form-account-btn-confirm-progress-icon"}
              color={theme.colors.white_primary}
            />
          ) : (
            <SideFormButtonLabel>Salvar</SideFormButtonLabel>
          )}
        </SideFormButton>
      </SideFormButtonsWrapper>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <SideFormPassword
        errors={formikUpdatePassword.errors}
        handleBlur={formikUpdatePassword.handleBlur}
        handleChange={formikUpdatePassword.handleChange}
        values={formikUpdatePassword.values}
      />
      <SideFormButtonsWrapper justifyContent="flex-start">
        <SideFormButton
          disabled={
            !formikUpdatePassword.dirty ||
            !formikUpdatePassword.isValid ||
            loadingPutAccount
          }
          id={"home-form-password-btn-confirm"}
          data-testid={"test-home-form-password-btn-confirm"}
          onClick={() => formikUpdatePassword.handleSubmit()}
          marginTop={20}
        >
          {loadingPutAccount ? (
            <Circle
              id={"home-form-password-btn-confirm-progress-icon"}
              color={theme.colors.white_primary}
            />
          ) : (
            <SideFormButtonLabel>Salvar</SideFormButtonLabel>
          )}
        </SideFormButton>
      </SideFormButtonsWrapper>
    </React.Fragment>
  );
};
