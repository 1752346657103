import React from "react";
import { useDispatch } from "react-redux";

import { setRouteName } from "../../../../../core/Navigation/actions";
import {
  AlertDocumentButton,
  AlertDocumentButtonLabel,
  AlertDocumentContainer,
  AlertDocumentContent,
  AlertDocumentDescription,
  AlertDocumentDescriptionContent,
  AlertDocumentIcon,
  AlertDocumentIconContainer,
} from "./style";

export interface IAlertDocumentProps {
  id: string;
  dataTestId?: string;
}

export const AlertDocument: React.FC<IAlertDocumentProps> = ({
  id,
  dataTestId = `test-${id}`,
}) => {
  const dispatch = useDispatch();

  const handleNavigationDocuments = React.useCallback(() => {
    dispatch(setRouteName("/documentos"));
  }, []);
  return (
    <AlertDocumentContainer id={id} data-testid={dataTestId}>
      <AlertDocumentContent>
        <AlertDocumentDescriptionContent>
          <AlertDocumentIconContainer>
            <AlertDocumentIcon />
          </AlertDocumentIconContainer>
          <AlertDocumentDescription>
            Você ainda não fez upload de documentos pessoais, complete seu
            cadastro para uma melhor experiência.
          </AlertDocumentDescription>
        </AlertDocumentDescriptionContent>
        <AlertDocumentButton onClick={handleNavigationDocuments}>
          <AlertDocumentButtonLabel>
            Ir para documentos
          </AlertDocumentButtonLabel>
        </AlertDocumentButton>
      </AlertDocumentContent>
    </AlertDocumentContainer>
  );
};
