import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setRouteName } from "../../core/Navigation/actions";
import { getRouteNameCurrentSelector } from "../../core/Navigation/selectors";
import { getPatientSelector } from "../../core/Patient/selectors";
import { setOpenProfileSide } from "../../core/Profile/actions";
import { greetings } from "../../utils/functions";
import { SideAccountProfile, SideOptions } from "./Components";
import {
  ProfileSideContainer,
  ProfileSideHeaderButton,
  ProfileSideHeaderGreetings,
  ProfileSideHeaderImage,
  ProfileSideHeaderLoggedContent,
  ProfileSideHeaderLoggedWrapper,
  ProfileSideHeaderName,
  ProfileSideHeaderWrapper,
} from "./style";
import { setLogout } from "../../core/Patient/actions";

interface IProfileSideProps {
  id: {
    profileSide: string;
    profileSideOptions: string;
    sideAccountProfile: string;
  };
  visible: boolean;
}

export const ProfileSide: React.FC<IProfileSideProps> = ({ id, visible }) => {
  const dispatch = useDispatch();
  const routeNameCurrent = useSelector(getRouteNameCurrentSelector);
  const patient = useSelector(getPatientSelector);

  const [openSideOptions, setOpenSideOptions] = React.useState(false);

  const changeGreetings = React.useCallback(() => {
    return `Olá, ${greetings()}.`;
  }, []);

  const changeNameUser = React.useCallback(() => {
    if (patient !== null) {
      if (patient.diversity?.socialName) {
        return patient.diversity.socialName;
      }
      if (patient?.firstName) {
        if (patient?.lastName) {
          return `${patient.firstName + " " + patient.lastName}`;
        }
        return `${patient.firstName}`;
      }
    } else {
      return "";
    }
  }, [patient]);

  const handleOpenSideOptions = React.useCallback(() => {
    setOpenSideOptions(true);
  }, []);

  const handleNavigationSideOptions = React.useCallback(
    (routeName: string, modalProfile: boolean, modalB2B: boolean) => {
      if (modalB2B) {
        setOpenSideOptions(false);
        dispatch(setRouteName("/home"));
      } else if (String(routeName) === "/profile") {
        setOpenSideOptions(false);
        dispatch(setOpenProfileSide(modalProfile));
      } else {
        dispatch(setRouteName(routeName));
        setOpenSideOptions(false);
        dispatch(setOpenProfileSide(modalProfile));
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    const elementSideAccountProfile =
      document.getElementById("SideAccountProfile");
    elementSideAccountProfile?.addEventListener("click", (props: any) => {
      if (props.target.id === "SideAccountProfile") {
        dispatch(setOpenProfileSide(false));
      }
    });
  }, [dispatch]);

  function clearCredentials() {
    localStorage.clear();
    dispatch(setLogout());
  }

  return (
    <React.Fragment>
      {visible && patient !== null && (
        <ProfileSideContainer id={id.profileSide}>
          <ProfileSideHeaderWrapper>
            <ProfileSideHeaderLoggedContent>
              <ProfileSideHeaderLoggedWrapper>
                <ProfileSideHeaderGreetings
                  isHome={routeNameCurrent === "/home"}
                >
                  {changeGreetings()}
                </ProfileSideHeaderGreetings>
                <ProfileSideHeaderName isHome={routeNameCurrent === "/home"}>
                  {changeNameUser()}
                </ProfileSideHeaderName>
              </ProfileSideHeaderLoggedWrapper>
              <ProfileSideHeaderButton
                id={"home-btn-profile-side-header"}
                onClick={handleOpenSideOptions}
              >
                <ProfileSideHeaderImage
                  id={"home-avatar-profile-side-header"}
                  size="50px"
                  borderRadius="25px"
                  src={patient?.photoProfile}
                />
              </ProfileSideHeaderButton>
            </ProfileSideHeaderLoggedContent>
          </ProfileSideHeaderWrapper>
        </ProfileSideContainer>
      )}
      <SideOptions
        id={id.profileSideOptions}
        onClick={(routeName, modalProfile, modalB2B) => {
          if (routeName === "/login") {
            clearCredentials();
          } else handleNavigationSideOptions(routeName, modalProfile, modalB2B);
        }}
        visible={openSideOptions}
      />
      {patient !== null && <SideAccountProfile id={id.sideAccountProfile} />}
    </React.Fragment>
  );
};
